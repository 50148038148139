<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{$t('players')}} - {{ division.name }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="players" :fields="columns" :filter="filter">
      <div slot="order" class="text-right" slot-scope="row">{{ row.index + 1 }}</div>
      <div slot="nick" slot-scope="row">
        <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: row.item.console.toLowerCase(), playerSlug: row.item.player_slug } }">{{ row.item.player_nick }}</router-link>
      </div>
      <div slot="team" slot-scope="row">
        <router-link :to="{ name: 'TeamBack', params: { lang: lang, console: console, teamId: row.item.team_id } }">{{ row.item.team_name }}</router-link>
      </div>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      players: [],
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'nick', label: this.$t('name'), sortable: true },
        { key: 'team', label: this.$t('team'), sortable: true },
        { key: 'actions', label: '' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournament',
      'division'
    ])
  },
  mounted() {
    if (!this.tournament.name) {
      this.$router.push({ name: 'Tournaments', params: { lang: this.lang, console: this.console } })
      return
    }
    document.title = `${this.tournament.name} - ${this.$t('players')} - ${this.division.name}`
    this.fetchData()
  },
  methods: {
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/players`
      this.$axios.get(path).then((response) => {
        this.players = response.data.data
      })
    }
  }
}
</script>
